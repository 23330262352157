export var ImageUploadError = /* @__PURE__ */ ((ImageUploadError2) => {
  ImageUploadError2["SIZE"] = "size";
  ImageUploadError2["TYPE"] = "type";
  ImageUploadError2["DIMENSION"] = "dimension";
  ImageUploadError2["MULTIPLE"] = "multiple";
  return ImageUploadError2;
})(ImageUploadError || {});
export var FilterUIType = /* @__PURE__ */ ((FilterUIType2) => {
  FilterUIType2["LIST"] = "list";
  FilterUIType2["BLOCK"] = "block";
  return FilterUIType2;
})(FilterUIType || {});
export var TypeSlideCarousel = /* @__PURE__ */ ((TypeSlideCarousel2) => {
  TypeSlideCarousel2["PREV"] = "prev";
  TypeSlideCarousel2["NEXT"] = "next";
  return TypeSlideCarousel2;
})(TypeSlideCarousel || {});
export var BadgeColor = /* @__PURE__ */ ((BadgeColor2) => {
  BadgeColor2["GRAY"] = "gray";
  BadgeColor2["PURPLE"] = "purple";
  BadgeColor2["TURQUOISE"] = "turquoise";
  BadgeColor2["TEAL"] = "teal";
  BadgeColor2["CYAN"] = "cyan";
  BadgeColor2["BLUE"] = "blue";
  BadgeColor2["YELLOW"] = "yellow";
  BadgeColor2["ORANGE"] = "orange";
  BadgeColor2["ROSE"] = "rose";
  BadgeColor2["PINK"] = "pink";
  BadgeColor2["MODERN"] = "modern";
  return BadgeColor2;
})(BadgeColor || {});
export var B2BBadgeType = /* @__PURE__ */ ((B2BBadgeType2) => {
  B2BBadgeType2["PILL"] = "pill";
  B2BBadgeType2["BADGE"] = "badge";
  return B2BBadgeType2;
})(B2BBadgeType || {});
export var Size = /* @__PURE__ */ ((Size2) => {
  Size2["SM"] = "sm";
  Size2["MD"] = "md";
  Size2["LG"] = "lg";
  return Size2;
})(Size || {});
export var BadgeType = /* @__PURE__ */ ((BadgeType2) => {
  BadgeType2["INFO"] = "info";
  BadgeType2["SUCCESS"] = "success";
  BadgeType2["ERROR"] = "error";
  BadgeType2["WARNING"] = "warning";
  BadgeType2["NEUTRAL"] = "neutral";
  return BadgeType2;
})(BadgeType || {});
export var BadgeBGColorB2B = /* @__PURE__ */ ((BadgeBGColorB2B2) => {
  BadgeBGColorB2B2["INFO"] = "primary-50";
  BadgeBGColorB2B2["SUCCESS"] = "green-100";
  BadgeBGColorB2B2["ERROR"] = "red-100";
  BadgeBGColorB2B2["WARNING"] = "orange-50";
  BadgeBGColorB2B2["NEUTRAL"] = "gray-50";
  return BadgeBGColorB2B2;
})(BadgeBGColorB2B || {});
export var BadgeBGColorB2C = /* @__PURE__ */ ((BadgeBGColorB2C2) => {
  BadgeBGColorB2C2["INFO"] = "badge-info";
  BadgeBGColorB2C2["SUCCESS"] = "badge-positive";
  BadgeBGColorB2C2["ERROR"] = "badge-negative";
  BadgeBGColorB2C2["WARNING"] = "badge-warning";
  BadgeBGColorB2C2["NEUTRAL"] = "badge-neutral";
  return BadgeBGColorB2C2;
})(BadgeBGColorB2C || {});
export var BadgeTextColorB2B = /* @__PURE__ */ ((BadgeTextColorB2B2) => {
  BadgeTextColorB2B2["INFO"] = "primary-500";
  BadgeTextColorB2B2["SUCCESS"] = "green-900";
  BadgeTextColorB2B2["ERROR"] = "red-900";
  BadgeTextColorB2B2["WARNING"] = "orange-700";
  BadgeTextColorB2B2["NEUTRAL"] = "gray-700";
  return BadgeTextColorB2B2;
})(BadgeTextColorB2B || {});
export var BadgeTextColorB2C = /* @__PURE__ */ ((BadgeTextColorB2C2) => {
  BadgeTextColorB2C2["INFO"] = "badge-info";
  BadgeTextColorB2C2["SUCCESS"] = "badge-positive";
  BadgeTextColorB2C2["ERROR"] = "badge-negative";
  BadgeTextColorB2C2["WARNING"] = "badge-warning";
  BadgeTextColorB2C2["NEUTRAL"] = "badge-neutral";
  return BadgeTextColorB2C2;
})(BadgeTextColorB2C || {});
export var BadgePaddingB2B = /* @__PURE__ */ ((BadgePaddingB2B2) => {
  BadgePaddingB2B2["XS"] = "px-1.5 h-6 py-0.5";
  BadgePaddingB2B2["SMALL"] = "px-2.5 h-6 py-0.5";
  BadgePaddingB2B2["LARGE"] = "py-1 px-4";
  BadgePaddingB2B2["MEDIUM"] = "px-2.5 h-7 py-1";
  BadgePaddingB2B2["XL"] = "px-4 py-1";
  return BadgePaddingB2B2;
})(BadgePaddingB2B || {});
export var BadgePaddingB2C = /* @__PURE__ */ ((BadgePaddingB2C2) => {
  BadgePaddingB2C2["XS"] = "px-1.5 py-0.5";
  BadgePaddingB2C2["SMALL"] = "px-1.5 h-6";
  BadgePaddingB2C2["MEDIUM"] = "px-2 py-1.5";
  BadgePaddingB2C2["LARGE"] = "px-3 py-1.5";
  BadgePaddingB2C2["XL"] = "px-4 py-1.5";
  return BadgePaddingB2C2;
})(BadgePaddingB2C || {});
export var ChargemapCtaIcon = /* @__PURE__ */ ((ChargemapCtaIcon2) => {
  ChargemapCtaIcon2["IconCaddie"] = "IconCaddie";
  ChargemapCtaIcon2["IconUserCircle"] = "IconUserCircle";
  return ChargemapCtaIcon2;
})(ChargemapCtaIcon || {});
